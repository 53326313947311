// src/routerConfig.js
import { Navigate } from 'react-router-dom';
import {
  AccountProfilePage,
  AccountOrderPage,
  LoginCoverPage,
  VerifyCodePage,
  RegisterCoverPage,
  ResetPasswordPage,
  Page404,
  Page500,
  HomePage,
  CareerBlogPage,
  CareerPostPage,
  SupportPage,
  ComingSoonPage,
  MaintenancePage,
  PrivacyPage,
  TermsPage,
} from './elements';
import { paths } from './paths';
// layouts
import MainLayout from '../layouts/main';
// import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import AccountSubPage from 'src/pages/account/AccountSubPage';
import Logo from 'src/sections/examples/animate/other/Logo';

function getRoutes(currentUser, logout, loading, t, i18n) {
  const ChromeLoginWrapper = () => {
    logout();
    return <Navigate to={ paths.loginCover } replace />;
  };
  const ChromeRegisterWrapper = () => {
    logout();
    return <Navigate to={ paths.registerCover } replace />;
  };
    const routes = [
        // Non layout
        {
          path: 'auth',
          children: [
            {
              path: 'login-cover',
              element: loading ? <LoginCoverPage t={t} i18n={i18n} /> : currentUser ?  <Navigate to={ paths.accountPage } replace /> : <LoginCoverPage t={t} i18n={i18n}/>,
            },
            {
              path: 'register-cover',
              element: loading ? <RegisterCoverPage t={t} i18n={i18n}/> : currentUser ? <Navigate to={ paths.accountPage } replace /> : <RegisterCoverPage t={t} i18n={i18n}/>,
            },
            {
              path: 'reset-password',
              element: <ResetPasswordPage t={t} i18n={i18n}/>,
            },
            {
              path: 'login-chrome',
              // run logout function and redirect to login page
              element: <ChromeLoginWrapper/>,
            },
            {
              path: 'register-chrome',
              // run logout function and redirect to register page
              element: <ChromeRegisterWrapper/>,
            },
          ],
        },
        // Main layout
        {
          element: <MainLayout />,
          children: [
            { element: <HomePage t={t} i18n={i18n}/>, index: true },
            { path: 'support', element: <SupportPage t={t} i18n={i18n}/> },
            {
              path: 'account',
              children: [
                { path: '', element: loading ? <AccountProfilePage t={t} i18n={i18n}/> : currentUser ? <AccountProfilePage t={t} i18n={i18n}/> : <Navigate to={ paths.loginCover } replace /> },
                { path: 'subscriptions', element: <AccountSubPage t={t} i18n={i18n}/> },
                // { path: 'orders', 
                //   element: <AccountOrderPage /> 
                // },
              ],
            },
            {
              path: 'blog',
              children: [
                { path: '', element: <CareerBlogPage t={t} i18n={i18n}/> },
                { path: 'post/:postId/*', element: <CareerPostPage t={t} i18n={i18n}/> },
              ],
            },        
          ],
        },
        {
          element: <MainLayout t={t} i18n={i18n}/>,
          children: [
            { path: 'privacy', element: <PrivacyPage t={t} i18n={i18n}/> },
            { path: 'term', element: <TermsPage t={t} i18n={i18n}/> },
          ],
        },
        {
          element: <CompactLayout t={t} i18n={i18n}/>,
          children: [
            { path: 'coming-soon', element: <ComingSoonPage t={t} i18n={i18n}/> },
            { path: 'maintenance', element: <MaintenancePage t={t} i18n={i18n}/> },
            { path: '500', element: <Page500 t={t} i18n={i18n}/> },
            { path: '404', element: <Page404 t={t} i18n={i18n}/> },
            {
              path: 'auth',
              children: [
                { path: 'reset-code', element: <ResetPasswordPage t={t} i18n={i18n}/> },
                { path: 'verify-code', element: <VerifyCodePage t={t} i18n={i18n}/> },
              ],
            },
          ],
        },
        { path: '*', element: <Navigate to="/404" replace /> },
      ];
  return routes;
}

export default getRoutes;
