// ----------------------------------------------------------------------

export const paths = {
  // Marketing
  marketing: {
    landing: '/marketing/landing',
    services: '/marketing/services',
    caseStudies: '/marketing/case-studies',
    caseStudy: `/marketing/case-study`,
    posts: '/marketing/posts',
    post: `/marketing/post`,
    about: '/marketing/about',
    contact: '/marketing/contact',
  },
  // Travel
  travel: {
    landing: '/travel/landing',
    tours: '/travel/tours',
    tour: `/travel/tour`,
    checkout: '/travel/checkout',
    orderCompleted: '/travel/order-completed',
    posts: '/travel/posts',
    post: `/travel/post`,
    about: '/travel/about',
    contact: '/travel/contact',
  },
  // Career
  career: {
    landing: '/career/landing',
    jobs: '/career/jobs',
    job: `/career/job`,
    posts: '/career/posts',
    post: `/career/post`,
    about: '/career/about',
    contact: '/career/contact',
  },
  // E-learning
  eLearning: {
    landing: '/e-learning/landing',
    courses: '/e-learning/courses',
    course: `/e-learning/course`,
    posts: '/e-learning/posts',
    post: `/e-learning/post`,
    about: '/e-learning/about',
    contact: '/e-learning/contact',
  },
  // E-commerce
  eCommerce: {
    landing: '/e-commerce/landing',
    products: '/e-commerce/products',
    product: `/e-commerce/product`,
    cart: '/e-commerce/cart',
    checkout: `/e-commerce/checkout`,
    orderCompleted: '/e-commerce/order-completed',
    wishlist: `/e-commerce/wishlist`,
    compare: `/e-commerce/compare`,
    account: {
      root: `/e-commerce/account`,
      personal: `/e-commerce/account/personal`,
      wishlist: `/e-commerce/account/wishlist`,
      vouchers: `/e-commerce/account/vouchers`,
      orders: `/e-commerce/account/orders`,
      payment: `/e-commerce/account/payment`,
    },
  },
  // Auth
  loginCover: '/auth/login-cover',
  loginBackground: '/auth/login-background',
  loginIllustration: '/auth/login-illustration',
  registerCover: '/auth/register-cover',
  registerBackground: '/auth/register-background',
  registerIllustration: '/auth/register-illustration',
  resetPassword: '/auth/reset-password',
  verifyCode: '/auth/verify-code',
  accountPage: '/account',
  accountSubPage: '/account/subscriptions',
  accountOrderPage: '/account/orders',  
  // Common
  maintenance: '/maintenance',
  comingsoon: '/coming-soon',
  pricing01: '/pricing-01',
  pricing02: '/pricing-02',
  payment: '/payment',
  support: '/support',
  blog: '/blog',
  blogPost: '/blog/post',
  privact: '/privacy',
  term: '/term',
  page404: '/404',
  page500: '/500',
  // Components
  components: {
    root: '/components',
    animate: '/components/animate',
    carousel: '/components/carousel',
    countUp: '/components/count-up',
    form: '/components/form-validation',
    icons: '/components/icons',
    image: '/components/image',
    label: '/components/label',
    lightbox: '/components/lightbox',
    markdown: '/components/markdown',
    megaMenu: '/components/mega-menu',
    navigation: '/components/navigation-bar',
    scroll: '/components/scroll',
    player: '/components/player',
    textMaxLine: '/components/text-max-line',
  },
  // Others
  pages: '/pages',
  docs: 'https://zone-docs.vercel.app',
  license: 'https://material-ui.com/store/license/#i-standard-license',
  minimalStore: 'https://material-ui.com/store/items/minimal-dashboard',
  zoneStore: 'https://mui.com/store/items/zone-landing-page/',
  figmaPreview:
    'https://www.figma.com/file/X6OyiGHF8dnTk3aT38P0oN/%5BPreview%5D-Zone_Web.30.03.23?node-id=0-1',
  iosApp: 'https://apps.apple.com/us/app/rift-vpn/id6449978224?platform=iphone',
  androidApp: 'https://play.google.com/store/apps/details?id=ankutv.riftvpn',
  chromeApp: 'https://chrome.google.com/webstore/detail/unblock-youku/pdnfnkhpgegpcingjbfihlkjeighnddk',
  supportForum: 'https://riftvpn.atlassian.net/servicedesk/customer/portal/1',
  supportBilling: 'https://riftvpn.atlassian.net/servicedesk/customer/portal/1/group/1/create/2',
  supportTechnical: 'https://riftvpn.atlassian.net/servicedesk/customer/portal/1/group/1/create/6',
  supportOther: 'https://riftvpn.atlassian.net/servicedesk/customer/portal/1/group/1/create/4',
  supportImprovement: 'https://riftvpn.atlassian.net/servicedesk/customer/portal/1/group/1/create/8',
  emilAddress: 'mailto:support@riftvpn.atlassian.net',
  discordUrl: 'https://go.riftvpn.ai/discord',
  stripeBilling: 'https://vip.riftvpn.ai/p/login/cN2eXvb6g8AldiweUU',
  googleBilling: 'https://play.google.com/store/account/subscriptions',
  appleBilling: 'https://apps.apple.com/account/subscriptions',
  stripeMonthlyPayment: 'https://go.riftvpn.ai/payment-link-monthly',
  stripeYearlyPayment: 'https://go.riftvpn.ai/payment-link-yearly',
  downloadPrefix: 'https://go.riftvpn.ai/download/',
  blogInternal: 'https://blog.riftvpn.ai',
};
