import _mock from '../_mock';

// ----------------------------------------------------------------------

export const _categories = [
  { label: 'Marketing', path: '' },
  { label: 'Community', path: '' },
  { label: 'Tutorials', path: '' },
  { label: 'Business', path: '' },
  { label: 'Management', path: '' },
];

// ----------------------------------------------------------------------

export const _tags = [
  { label: 'Marketing', path: '' },
  { label: 'Development', path: '' },
  { label: 'Banking', path: '' },
  { label: 'HR & Recruting', path: '' },
  { label: 'Design', path: '' },
  { label: 'Management', path: '' },
  { label: 'Business', path: '' },
  { label: 'Community', path: '' },
  { label: 'Tutorials', path: '' },
];

// ----------------------------------------------------------------------

// export const _testimonials = [...Array(8)].map((_, index) => ({
//   id: _mock.id(index),
//   name: _mock.name.fullName(index),
//   role: _mock.role(index),
//   avatar: _mock.image.avatar(index),
//   postDate: _mock.time(index),
//   rating: 5,
//   review:
//     'Amazing experience i love it a lot. Thanks to the team that dreams come true, great! I appreciate there attitude and approach.',
// }));

export const  _testimonials = [
  {
    id: '1',
    name: 'DwHeartLion',
    role: '',
    avatar: '/static/mock-images/avatars/avatar_1.jpg',
    postDate: 'Feb 12, 2021',
    rating: 5,
    review:
      '谢谢你们的付出，感恩',
  },
  {
    id: '2',
    name: '陈有豪',
    role: '',
    avatar: '/static/mock-images/avatars/avatar_2.jpg',
    postDate: 'Feb 12, 2021',
    rating: 5,
    review:
      '真的超级好用啊，感谢制作者哟~~~',
  },
  {
    id: '3',
    name: 'Josh Chan',
    role: '',
    avatar: '/static/mock-images/avatars/avatar_3.jpg',
    postDate: 'Feb 12, 2021',
    rating: 5,
    review:
      '非常棒 如果开个VIP通道的话我会买的',
  },
  {
    id: '4',
    name: 'Yue Yu',
    role: '',
    avatar: '/static/mock-images/avatars/avatar_1.jpg',
    postDate: 'Feb 12, 2021',
    rating: 5,
    review:
      'Savior of 留学生',
  },
  {
    id: '5',
    name: 'Liwei Sun',
    role: 'UX Designer at Shopify',
    avatar: '/static/mock-images/avatars/avatar_2.jpg',
    postDate: 'Feb 12, 2021',
    rating: 5,
    review:
      'Used for >5 years. Always work!',
  },
  {
    id: '6',
    name: 'Ken Su',
    role: '',
    avatar: '/static/mock-images/avatars/avatar_3.jpg',
    postDate: 'Feb 12, 2021',
    rating: 5,
    review:
      '加油!',
  },
  {
    id: '7',
    name: 'spek_ll-1208',
    role: '',
    avatar: '/static/mock-images/avatars/avatar_1.jpg',
    postDate: 'Feb 12, 2021',
    rating: 5,
    review:
      '刚试了, 能看芒果tv了, 太感谢了',
  },
  {
    id: '8',
    name: 'Udon LIN',
    role: '',
    avatar: '/static/mock-images/avatars/avatar_2.jpg',
    postDate: 'Feb 12, 2021',
    rating: 5,
    review:
      '很穩很有用',
  },
  {
    id: '9',
    name: 'Dian Tan',
    role: '',
    avatar: '/static/mock-images/avatars/avatar_3.jpg',
    postDate: 'Feb 12, 2021',
    rating: 5,
    review:
      'Great extension. 5 stars!',
  },
  {
    id: '10',
    name: 'Marty Luk',
    role: '',
    avatar: '/static/mock-images/avatars/avatar_3.jpg',
    postDate: 'Feb 12, 2021',
    rating: 5,
    review:
      'extremely helpful for watching live football games on chinese websites such as PPTV, Tencent Live etc.',
  },
]

// ----------------------------------------------------------------------

export const _socials = [
  {
    value: 'discord',
    label: 'Discord',
    icon: 'carbon:logo-discord',
    color: '#00AAEC',
    link: 'https://go.riftvpn.ai/discord',
  },
  {
    value: 'twitter',
    label: 'Twitter',
    icon: 'carbon:logo-twitter',
    color: '#00AAEC',
    link: 'https://go.riftvpn.ai/twitter',
  },
  {
    value: 'facebook',
    label: 'FaceBook',
    icon: 'carbon:logo-facebook',
    color: '#1877F2',
    link: 'https://go.riftvpn.ai/fb-page',
  },
];

// ----------------------------------------------------------------------

const LAT_LONG = [
  [33, 65],
  [-12.5, 18.5],
  [20.96, 26.27],
];

export const _offices = ['Jordan', 'Canada', 'Portugal'].map((office, index) => ({
  id: _mock.id(index),
  country: office,
  address: _mock.address.fullAddress(index),
  phoneNumber: _mock.phoneNumber(index),
  email: _mock.email(index),
  photo: _mock.image.travel(index + 4),
  latlng: LAT_LONG[index],
}));

// ----------------------------------------------------------------------

const BRANDS_NAME = [
  'airbnb',
  'dropbox',
  'facebook',
  'google',
  'heroku',
  'lenovo',
  'microsoft',
  'netflix',
  'slack',
  'spotify',
  'tripadvisor',
  'vimeo',
];

export const _brands = BRANDS_NAME.map((brand, index) => ({
  id: _mock.id(index),
  name: brand,
  image: `/assets/logo/${brand}.svg`,
}));

export const _brandsColor = BRANDS_NAME.map((brand, index) => ({
  id: _mock.id(index),
  name: brand,
  image: `/assets/logo/${brand}_original.svg`,
}));

// ----------------------------------------------------------------------

export const _faqs = [
  'Sed augue ipsum, egestas nec, vestibulum et',
  'alesuada adipiscing, dui vestibulum suscipit nulla quis orci.',
  'Ut varius tincidunt libero',
  'In ut quam vitae odio lacinia tincidunt.',
  'Fusce vel dui Morbi nec metus.',
].map((question, index) => ({
  id: _mock.id(index),
  question,
  answer:
    'Amazing experience i love it a lot. Thanks to the team that dreams come true, great! I appreciate there attitude and approach.',
}));

export function _faqsSupport(t){
  const _faqsSupportStr = [
    {
      id: _mock.id(0),
      question: t('faq.what_is_vpn'),
      answer: t('faq.what_is_vpn_answer'),
    },
    {
      id: _mock.id(1),
      question: t('faq.what_devices'),
      answer: t('faq.what_devices_answer'),
    },
    {
      id: _mock.id(2),
      question: t('faq.will_vpn_slow'),
      answer: t('faq.will_vpn_slow_answer'),
    },
    {
      id: _mock.id(3),
      question: t('faq.will_vpn_log'),
      answer: t('faq.will_vpn_log_answer'),
    },
    {
      id: _mock.id(4),
      question: t('faq.how_to_contact'),
      answer: t('faq.how_to_contact_answer'),
    },
  ];
  return _faqsSupportStr; 
}



