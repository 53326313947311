
// @mui
import { Box, Typography, Stack, IconButton, InputAdornment } from '@mui/material';
// components
import PricingHome from '../../pricing/home';
import { _pricingHome } from 'src/_mock';
//
import { AccountLayout } from '../layout';
// ----------------------------------------------------------------------


export default function AccountSubView({ t }) {
  return (
    <PricingHome plans={_pricingHome} t={t} />
  );
}
