// routes
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------
export function pageLinks(t) {
  const pageLinksStr = [
    {
      order: '1',
      subheader: t('menu.foot.download.title'),
      cover: '/assets/images/menu/menu_marketing.jpg',
      items: [
        { title: t('menu.foot.download.android'), path: paths.androidApp },
        { title: t('menu.foot.download.ios'), path: paths.iosApp },
        { title: t('menu.foot.download.androidTv'), path: paths.downloadPrefix + "androidtv" },
        { title: t('menu.foot.download.mac'), path: paths.iosApp },
        { title: t('menu.foot.download.chrome'), path: paths.chromeApp },
      ],
    },
    {
      order: '2',
      subheader: t('menu.foot.support.title'),
      cover: '/assets/images/menu/menu_travel.jpg',
      items: [
        { title: t('menu.foot.support.faq'), path: paths.support },
        { title: t('menu.foot.support.privacy'), path: paths.privact },
        { title: t('menu.foot.support.terms'), path: paths.term },
      ],
    },
  
    {
      order: '3',
      subheader: t('menu.foot.account.title'),
      cover: '/assets/images/menu/menu_elearning.jpg',
      items: [
        { title: t('menu.foot.account.manage'), path: paths.accountPage },
        { title: t('menu.foot.account.forget'), path: paths.resetPassword },
      ],
    },
    {
      order: '4',
      subheader: t('menu.foot.blog.title'),
      cover: '/assets/images/menu/menu_social.jpg',
      items: [
        { title: t('menu.foot.blog.news'), path: paths.blog },
      ],
    },
    
  ];
  return pageLinksStr;
}

export function navConfig(t) {
  const navConfigStr = [
    { title: t('menu.top.download'), path: '/' },
    { title: t('menu.top.account'), path: paths.accountPage },
    { title: t('menu.top.support'), path: paths.support },
    { title: t('menu.top.blog'), path: paths.blog },
  ];
  return navConfigStr;
}

