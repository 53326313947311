/* global chrome */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getFunctions, httpsCallable } from "firebase/functions";
import { useTranslation} from 'react-i18next';
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [memberTier, setEntitlement] = useState(null); // State to store user's entitlement
  const [referralNewUser, setReferralNewUser] = useState(false); // State to store if the user is a new referral user
  const [subscriptionSource, setSubscriptionSource] = useState(null); // State to store the subscription source
  const auth = getAuth();
  const functions = getFunctions(); // Initialize Cloud Functions
  const getUserSubscriptionSource = httpsCallable(functions, 'getUserSubscriptionSource'); // Reference to the callable function
  const setChromeClaim = httpsCallable(functions, 'setChromeClaim'); // Reference to the callable function

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      setLoading(false);
      setEntitlement(t('pages.pricing.unregistered')); // Set the entitlement to default value


      if (user) {
        try {
          const idTokenResult = await user.getIdTokenResult();
          // Confirm the user has a premium entitlement.
          if (
            idTokenResult.claims.revenueCatEntitlements &&
            Array.isArray(idTokenResult.claims.revenueCatEntitlements) &&
            idTokenResult.claims.revenueCatEntitlements.includes('pro')
          ) {
            setEntitlement(t('pages.pricing.premium.starter'));
          } else {
            setEntitlement(t('pages.pricing.basic'));
          }
          const userTier = idTokenResult.claims.revenueCatEntitlements?.includes('pro') ? t('pages.pricing.premium.starter') : t('pages.pricing.basic');
          if (userTier === t('pages.pricing.premium.starter')) {
            const subscriptionSource = await getUserSubscriptionSource();
            setSubscriptionSource(subscriptionSource.data);
          }

          // Attempt to set chrome claim if chromeClaim exists in localStorage
          const chromeClaim = localStorage.getItem('chromeClaim');
          if (chromeClaim) {
            if (idTokenResult.claims.chromeClaim){
              localStorage.removeItem('chromeClaim');
            } else {
              await setChromeClaim({ chromeClaim });
              localStorage.removeItem('chromeClaim'); // Clean up after setting the claim
            }
          }
          
        // Store user and tier information in localStorage
        localStorage.setItem('user', JSON.stringify({ uid: user.uid, email: user.email }));
        localStorage.setItem('memberTier', userTier);
        if(idTokenResult.claims.referrerUid && idTokenResult.claims.referrerUid !== '' && userTier === t('pages.pricing.basic')) {
          localStorage.setItem('referralNewUser', 'true');
        }
        if (localStorage.getItem('referralNewUser') === 'true' && userTier === t('pages.pricing.basic')) {
          setReferralNewUser(true);
        }
        }
        catch (error) {
          console.error('Error while getting user token:', error);
        }
      } else{
        // Clear storage if user logs out
        localStorage.removeItem('user');
        localStorage.removeItem('memberTier');
      }
        
    });

    return () => unsubscribe();
  }, [auth]);

  // Function to log out the user
  const logout = async () => {
    try {
      await signOut(auth)
        .then(() => {
          var editorExtensionId = "pdnfnkhpgegpcingjbfihlkjeighnddk";
          // Notify the extension that the user has logged out
          chrome.runtime.sendMessage(editorExtensionId, { type: 'FROM_PAGE_TO_CONTENT_SCRIPT', message: 'logout' });
        });
        
      setCurrentUser(null); // Clear the user data
      setEntitlement(t('pages.pricing.unregistered')); // Reset the entitlement
      setReferralNewUser(false); // Reset the referral user status
      localStorage.removeItem('user');
      localStorage.removeItem('memberTier');
      localStorage.removeItem('referralNewUser');
      localStorage.removeItem('promo');
      setSubscriptionSource(null);
    } catch (error) {
      console.error('Error wfhile signing out:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser, logout, loading, memberTier, referralNewUser, subscriptionSource }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
