import { Helmet } from 'react-helmet-async';
// sections
import { AccountSubView } from 'src/sections/account/view';

// ----------------------------------------------------------------------

export default function AccountSubPage({ t }) {
  return (
    <>
      <Helmet>
        <title> {t('pages.account.menu.buySubscription')} | {t('common.appName')} </title>
      </Helmet>

      <AccountSubView t={t} />
    </>
  );
}
