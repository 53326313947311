import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Router, useLocation } from 'react-router-dom';
// @mui
import { List, Drawer, IconButton, Button, Stack } from '@mui/material';
// config
import { NAV } from 'src/config-global';
// components
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
//
import { paths } from 'src/routes/paths';
import NavList from './NavList';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from 'src/AuthContext';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function NavMobile({ data }) {
  const { pathname } = useLocation();
  const { currentUser } = useAuth();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleOpen} sx={{ ml: 1, color: 'inherit' }}>
        <Iconify icon="carbon:menu" />
      </IconButton>

      <Drawer
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            pb: 5,
            width: NAV.W_BASE,
          },
        }}
      >
        <Scrollbar>
          <Logo sx={{ my: 3 }} />

          <List component="nav" disablePadding>
            {data.map((link) => (
              <NavList key={link.title} item={link} />
            ))}
          </List>

          <Stack spacing={1.5} sx={{ p: 3 }}>
            <Button fullWidth variant="contained" color="inherit" component={ RouterLink } to={paths.loginCover}>
              { currentUser ? t('pages.nav.mobile.manageUser') : t('pages.nav.mobile.login') }
            </Button>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}

NavMobile.propTypes = {
  data: PropTypes.array,
};
