import PropTypes from 'prop-types';
import { Card, Stack, Button, Typography } from '@mui/material';
import Label from 'src/components/label';
import Image from 'src/components/image';
import { useAuth } from 'src/AuthContext';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { paths } from 'src/routes/paths';
export default function PlanCard({ plan, isYearly, t }) {
  const { currentUser, memberTier, referralNewUser } = useAuth();
  
  const getButtonPath = () => {
    if (currentUser === null) {
      return paths.registerCover;
    }
    const promoActive = localStorage.getItem('promo') !== null && localStorage.getItem('promo') !== '';

    if (starterLicense) {
      const basePath = isYearly ? paths.stripeYearlyPayment : paths.stripeMonthlyPayment;
      if (promoActive) {
        return `${basePath}?prefilled_email=${currentUser?.email || ''}&prefilled_promo_code=${localStorage.getItem('promo')}`;
      } else if (referralNewUser) {
        return `${basePath}?prefilled_email=${currentUser?.email || ''}&prefilled_promo_code=FRIENDSSREF46623`;
      } 
      else{
        return `${basePath}?prefilled_email=${currentUser?.email || ''}`;
      }
    }
    return paths.stripeMonthlyPayment; // Default case
  };
    
  const { license, icon, options, yearlyPrice, monthlyPrice } = plan;
  const basicLicense = license === t('pages.pricing.basic');
  const starterLicense = license === t('pages.pricing.premium.starter');
  const premiumLicense = license === t('pages.pricing.premium.pro');
  let monthlyPriceNumber = Number(plan.monthlyPrice);
  let yearlyPriceNumber = Number(plan.yearlyPrice);
  let monthlyPriceForYear = monthlyPriceNumber * 12;
  let discountAmount = monthlyPriceForYear - yearlyPriceNumber;
  let discountPercentage = (discountAmount / monthlyPriceForYear) * 100;
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false); // it's assumed the membership tier is already loaded from local storage or server
  }, []);
  
  return (
    <Card
      sx={{
        p: 5,
        textAlign: 'center',
        boxShadow: (theme) => theme.customShadows.z8,
        ...(starterLicense && {
          py: 8,
          boxShadow: (theme) => theme.customShadows.z24,
        }),
      }}
    >
      {starterLicense && (
        <Label color="info" sx={{ position: 'absolute', top: 16, right: 16 }}>
          {t('pages.pricing.recommended')}
        </Label>
      )}

      <Stack spacing={5} alignItems="center">
        <Typography variant="overline" component="div" sx={{ color: 'text.secondary' }}>
          {license}
        </Typography>

        <Image alt={icon} src={icon} sx={{ width: 80, height: 80 }} />
        {isYearly && !basicLicense && (
          <Typography variant="body1" component="div" sx={{ color: 'text.primiary' }}>
          {discountPercentage.toFixed(0)}% {t('pages.pricing.off')}
          - {t('pages.pricing.save')} ${discountAmount.toFixed(0)}
          </Typography>
        )}
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
          {!basicLicense && (
            <Typography variant="h4" component="span">
              $
            </Typography>
          )}

       <Typography variant="h3" component="span">
          {isYearly ? yearlyPrice : monthlyPrice}
        </Typography>


        {!basicLicense && (
          <Typography variant="subtitle2" component="span">
            /{isYearly ? t('pages.pricing.yearly') : t('pages.pricing.monthly')}
          </Typography>
        )}



        </Stack>

        <Stack spacing={1} sx={{ textAlign: 'center' }}>
          {options.map((option) => (
            <Typography
              key={option.title}
              variant={option.disabled ? 'body2' : 'subtitle2'}
              sx={{
                ...(option.disabled && {
                  color: 'text.disabled',
                  textDecoration: 'line-through',
                }),
              }}
            >
              {option.title}
            </Typography>
          ))}
        </Stack>

        <Button
          fullWidth
          disabled={(basicLicense && memberTier !== t('pages.pricing.unregistered') || (starterLicense && memberTier === (t('pages.pricing.premium.starter') || t('pages.pricing.premium.pro')) ) || premiumLicense)}
          size="large"
          variant={basicLicense ? 'outlined' : 'contained'}
          color={premiumLicense ? 'primary' : 'inherit'}
          component={ RouterLink }
          // check if the user is already logged in or not if not redirect to login page
          // if the user is logged in and the license is starterLicense redirect to the stripeMonthly page
          // starterLicense ? isYearly ? paths.stripeYearlyPayment : paths.stripeMonthlyPayment : paths.stripeMonthlyPayment}
          to={getButtonPath()}
          >
          {isLoading ? 'Loading...' : (
          <>
            {basicLicense  && (memberTier === t('pages.pricing.unregistered') ? t('pages.pricing.register.free') : memberTier === t('pages.pricing.basic') ? t('pages.pricing.current') : t('pages.pricing.upgrade'))}
            {starterLicense && (memberTier === t('pages.pricing.premium.starter') || memberTier === t('pages.pricing.premium.pro') ? t('pages.pricing.current') : t('pages.pricing.upgrade'))}
            {premiumLicense && t('pages.pricing.comming_soon')}
          </>
          )}
        </Button>
      </Stack>
    </Card>
  );
}

PlanCard.propTypes = {
  plan: PropTypes.shape({
    icon: PropTypes.node,
    license: PropTypes.string,
    options: PropTypes.array,
    monthlyPrice: PropTypes.string,
    yearlyPrice: PropTypes.string,
  }),
};