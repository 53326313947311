import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link, Stack, Button, Typography, AppBar, Toolbar, Container } from '@mui/material';
// hooks
import useOffSetTop from 'src/hooks/useOffSetTop';
import useResponsive from 'src/hooks/useResponsive';
// utils
import { bgBlur } from 'src/utils/cssStyles';
// routes
import { paths } from 'src/routes/paths';
// config
import { HEADER } from 'src/config-global';
// components
import Logo from 'src/components/logo';
import SettingsDrawer from 'src/components/settings/drawer';
//
import { NavMobile, NavDesktop, navConfig } from '../nav';
import HeaderShadow from '../../components/HeaderShadow';
import { useAuth } from 'src/AuthContext';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Searchbar from 'src/layouts/components/Searchbar';
import Iconify from '../../../components/iconify';
// ----------------------------------------------------------------------

export default function Header({ headerOnDark }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { currentUser } = useAuth();


  const isMdUp = useResponsive('up', 'md');

  const isOffset = useOffSetTop();

  return (
    <AppBar color="transparent" sx={{ boxShadow: 'none' }}>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(headerOnDark && {
            color: 'common.white',
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            color: 'text.primary',
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}
      >
        <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
          <Box sx={{ lineHeight: 0, position: 'relative' }}>
            <Logo />

            
            {/* <Link href="https://zone-docs.vercel.app/changelog" target="_blank" rel="noopener">
              <Label
                color="info"
                sx={{
                  ml: 0.5,
                  px: 0.5,
                  top: -5,
                  left: 36,
                  height: 20,
                  fontSize: 11,
                  cursor: 'pointer',
                  position: 'absolute',
                }}
              >
                云界VPN
              </Label>
            </Link> */}
          </Box>

          {isMdUp && <NavDesktop data={navConfig(t)} />}

          <Stack
            spacing={2}
            flexGrow={1}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >

            <Stack spacing={1} direction="row" alignItems="center">
              {!isMdUp && window.location.pathname !== '/' && (
                <Button
                  variant="contained"
                  fullWidth
                  color="inherit"
                  component={RouterLink}
                  to={'/'}
                >
                <Iconify width={22} icon={'carbon:download'}/>

                </Button>
              )}
 


              <SettingsDrawer />                         
             <Searchbar t={t} />

            </Stack>

          {isMdUp && (
            currentUser ? (
              <Button
                variant="contained"
                color="inherit"
                component={RouterLink}
                to={paths.accountPage}
              >
                {t('pages.header.manageAccount')}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="inherit"
                component={RouterLink}
                to={paths.loginCover}
              >
                {t('pages.header.login')}
              </Button>
            )
          )}
          </Stack>

          {!isMdUp && <NavMobile data={navConfig(t)} />}

        </Container>
      </Toolbar>

      {isOffset && <HeaderShadow />}
    </AppBar>
  );
}

Header.propTypes = {
  headerOnDark: PropTypes.bool,
};
