import { useEffect } from 'react';
import { Box, Stack, Drawer, Divider, Typography, IconButton, Tooltip, MenuItem, Select } from '@mui/material';
// config
import { NAV } from 'src/config-global';
// components
import Iconify from '../../iconify';
import Scrollbar from '../../scrollbar';
import { useSettingsContext } from '../SettingsContext';
import BadgeDot from './components/BadgeDot';
import ModeOptions from './components/ModeOptions';
import ToggleButton from './components/ToggleButton';
import DirectionOptions from './components/DirectionOptions';
import ColorPresetsOptions from './components/ColorPresetsOptions';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function SettingsDrawer() {
  const { open, onToggle, onClose, onResetSetting, notDefault } = useSettingsContext();
  const { t, i18n } = useTranslation();

  const changeLanguageHandler = (event) => {
    localStorage.setItem('language', event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  useEffect(() => {
    // Set default language or get from storage
    i18n.changeLanguage(localStorage.getItem('language') || 'zh-Hans');

  }, [i18n]);

  return (
    <>
      <ToggleButton open={open} notDefault={notDefault} onToggle={onToggle} />
      <ModeOptions />
      <Drawer
        open={open}
        anchor="right"
        PaperProps={{
          sx: {
            width: NAV.W_DRAWER,
            bgcolor: 'background.default',
          },
        }}
        onClose={onClose}
      >
        <Stack direction="row" alignItems="center" sx={{ py: 2, px: 3 }}>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            {t('pages.settings.language_change')}
          </Typography>
          <IconButton onClick={onClose}>
            <Iconify icon="carbon:close" />
          </IconButton>
        </Stack>
        <Scrollbar>
          <Box sx={{ pb: 10 }}>
          <Stack spacing={2} sx={{ px: 3, py: 2 }}>
              <Select
                value={i18n.language}
                onChange={changeLanguageHandler}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="zh-Hans">简体中文</MenuItem>
                <MenuItem value="zh-HanT">繁體中文</MenuItem>
                <MenuItem value="en">English</MenuItem>
              </Select>
            </Stack>
          </Box>
        </Scrollbar>
      </Drawer>
    </>
  );
}
