// scroll bar
import 'simplebar-react/dist/simplebar.min.css';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
// lightbox
/* eslint-disable import/no-unresolved */
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import { AuthProvider, useAuth } from 'src/AuthContext';
import { useEffect, useState } from 'react';
import React, { useContext } from 'react';
// ----------------------------------------------------------------------

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// routes
import Router from 'src/routes';
// theme
import ThemeProvider from 'src/theme';
// components
import ScrollToTop from 'src/components/scroll-to-top';
import { ThemeSettings, SettingsProvider } from 'src/components/settings';
import MotionLazyContainer from 'src/components/animate/MotionLazyContainer';
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { getFunctions, httpsCallable } from 'firebase/functions';
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBXVBXNBWEj-rgy_P_JxoY8U3l_RibE7mk",
  authDomain: "anku-e1b04.firebaseapp.com",
  projectId: "anku-e1b04",
  storageBucket: "anku-e1b04.appspot.com",
  messagingSenderId: "205916028918",
  appId: "1:205916028918:web:22b8eb4047da7ac72a91d7",
  measurementId: "G-CQDT9WH0H8"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider('6LdG4XAmAAAAACtt4yWSzPWwJ8LYx7sLGofYWIDi'),
  isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});
const functions = getFunctions(app);
const checkMembership = httpsCallable(functions, 'checkMembership');
export const createContact = httpsCallable(functions, 'createContact');

// async function getMemberData() {
//   try {
//     const user = getAuth().currentUser;
//     if (user) {
//       const storedMembershipData = localStorage.getItem('memberData');
//       if (storedMembershipData){
//         const memberData = JSON.parse(storedMembershipData);
//         return Promise.resolve({status: 'success', data: memberData?.data?.data, message: 'from local storage'});
//       } else {
        
//         const result = await checkMembership({app_user_id: user.uid});
//         localStorage.setItem('memberData', JSON.stringify(result));
//         return Promise.resolve({status: 'success', data: result?.data?.data, message: 'from server'});
//       }
//     }
//     return Promise.resolve({status: 'notloggedin', data: null, message: ''});
//   } catch (error) {
//     console.log(error);
//     return Promise.reject({status: 'error', data: null, message: error});
//   }
// }


// async function getMemberTier() {
//   const memberData = await getMemberData();
//   if (memberData && memberData.status === 'success') {
//     const entitlements = memberData?.data?.subscriber?.entitlements;
//     if (entitlements) {
//       if (entitlements.proplus) {
//         return {status: 'success', data: 'proplus', message: memberData.message};
//       } else if (entitlements.pro) {
//         return {status: 'success', data: 'pro', message: memberData.message};
//       } else { 
//         return {status: 'success', data: 'free', message: memberData.message};
//       }
//     } else {
//       // data structure doesn't match the expectation
//       return {status: 'error', data: null, message: 'Unexpected data structure.'};
//     }
//   } else {
//     return memberData;
//   }
// }


// export function useMembershipTier() {
//   const [localizedMembershipTier, setLocalizedMembershipTier] = useState(

//   useEffect(() => {
//     // const localizeMembershipTier = (tier) => {
//     //   switch(tier) {
//     //     case 'pro':
//     //       return 'VIP会员';
//     //     case 'proplus':
//     //       return 'SVIP会员';
//     //     case 'free':
//     //       return '免费用户';
//     //     default:
//     //       return '未注册'; // Assuming this is the default or fallback case
//     //   }
//     // };
//     const user = getAuth().currentUser;
//     if (user === null) {
//       setLocalizedMembershipTier('未注册');
//     } else {
//       user.getIdTokenResult()
//       .then((idTokenResult) => {
//         // Confirm the user has a premium entitlement.
//         if (idTokenResult.claims.activeEntitlements && Array.isArray(idTokenResult.claims.activeEntitlements) && idTokenResult.claims.activeEntitlements.includes("pro")) {
//           setLocalizedMembershipTier('VIP会员');
//         } else {
//           setLocalizedMembershipTier('免费用户');
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//     }



//     // // Listen for changes in the membership tier from AuthContext
//     // setLocalizedMembershipTier(localizeMembershipTier(entitlement));

//     // Optionally, synchronize with local storage if needed
//     const onStorageUpdate = (e) => {
//       if (e.key === "membershipTier") {
//         setLocalizedMembershipTier(e.newValue);
//       }
//     };

//     window.addEventListener("storage", onStorageUpdate);

//     return () => {
//       window.removeEventListener("storage", onStorageUpdate);
//     };
//   });

//   return localizedMembershipTier;
// }



// ----------------------------------------------------------------------

export default function App() {
  const { t, i18n } = useTranslation();
  return (
    <AuthProvider>
    <HelmetProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider>
          <BrowserRouter>
            <ScrollToTop />
            <ThemeProvider>
              <ThemeSettings>
                <MotionLazyContainer>
                  <Router t={t} i18n={i18n} />
                </MotionLazyContainer>
              </ThemeSettings>
            </ThemeProvider>
          </BrowserRouter>
        </SettingsProvider>
      </LocalizationProvider>
    </HelmetProvider>
    </AuthProvider>
  );
}
