import PropTypes from 'prop-types';
import { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
import Image from 'src/components/image';
// ----------------------------------------------------------------------

function Logo({ single = false, sx }) {
  const theme = useTheme();

  return (
    <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...sx,
    }}>
    
    <Link
      component={RouterLink}
      to="/"
      color="inherit"
      aria-label="go to homepage"
      sx={{ lineHeight: 0 }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...sx,
        }}
        
      >
        <Image
          visibleByDefault
          disabledEffect
          alt="web icon"
          src="/assets/images/rift/icon.jpg"
          sx={{ width: 32, height: 32 }}
        />
        
      </Box>
    </Link>
    <Typography variant="logotitle" sx={{ ml: 1 }}>
      云界VPN
    </Typography>
      
    </Box>
  );
}

Logo.propTypes = {
  single: PropTypes.bool,
  sx: PropTypes.object,
};

export default memo(Logo);
