import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useEffect } from 'react';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword, updateProfile } from "firebase/auth";

import { useAuth } from 'src/AuthContext';
import { paths } from 'src/routes/paths';


// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Typography, Stack, IconButton, InputAdornment } from '@mui/material';
// components
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
//
import { AccountLayout } from '../layout';
// ----------------------------------------------------------------------


export default function AccountPersonalView({ t }) {
  const [showPassword, setShowPassword] = useState(false);

  const { currentUser, referralNewUser } = useAuth();

  const promoActive = localStorage.getItem('promo') !== null && localStorage.getItem('promo') !== '';

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const AccountPersonalSchema = Yup.object().shape({
    emailAddress: Yup.string().required('Email address is required'),
  });

  const getButtonPath = () => {
    if (currentUser === null) {
      return paths.registerCover;
    }
      const basePath = paths.stripeMonthlyPayment;
      if (promoActive) {
        return `${basePath}?prefilled_email=${currentUser?.email || ''}&prefilled_promo_code=${localStorage.getItem('promo')}`;
      } else if (referralNewUser) {
        return `${basePath}?prefilled_email=${currentUser?.email || ''}&prefilled_promo_code=FRIENDSSREF46623`;
      } 
      else{
        return `${basePath}?prefilled_email=${currentUser?.email || ''}`;
      }
  };

  const defaultValues = {
    name: '',
    emailAddress: '',
    phoneNumber: '',
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const methods = useForm({
    resolver: yupResolver(AccountPersonalSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const { setValue } = methods;
  
  useEffect(() => {
    
    const user = currentUser;
    
    if (user) {
      setValue("emailAddress", user.email);
      setValue("name", user.displayName);
      setValue("phoneNumber", user.phoneNumber);
      // Do the same for other fields as necessary
    } else {

    }
  }, [setValue]);

  const onSubmit = async (data) => {
    try {
      const user = currentUser;

      if (data.newPassword === data.confirmNewPassword) {
        // Reauthenticate before changing password
        const credential = EmailAuthProvider.credential(
          user.email,
          data.oldPassword,
        );
        await reauthenticateWithCredential(user, credential);
        await updatePassword(user, data.newPassword);
        
        // Reset the form values
        reset();
      } else {
        console.error("New passwords do not match");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onUpdateProfile = async (data) => {
    try {
      await updateProfile(currentUser, {
        displayName: data.name,
        //photoURL: data.photoURL, // update this as per your form
      });

      console.log('Profile updated!');
    } catch (error) {
      console.error('An error occurred: ', error);
    }
  };
  

  return (
    <AccountLayout>
          <Box
            sx={{
              p: 2,
              mb: 3,
              borderRadius: 1,
              // highlight the background color in transparent yellow
              bgcolor: (theme) => (theme.palette.mode === 'light' ? 'warning.light' : 'warning.dark'),
              
              color: 'text.primary',
            }}
          >
            <Typography variant="body2">
              <a style={{ textDecoration: 'none', color: 'inherit' }}>
                {t('pages.auth.register.chrome_update')}
              </a>
            </Typography>
          </Box>
        {referralNewUser && (
          <Box
            sx={{
              p: 2,
              mb: 3,
              borderRadius: 1,
              // highlight the background color in transparent yellow
              bgcolor: (theme) => (theme.palette.mode === 'light' ? 'warning.light' : 'warning.dark'),
              
              color: 'text.primary',
            }}
          >
            <Typography variant="body2">
              <a href={getButtonPath()} style={{ textDecoration: 'none', color: 'inherit' }}>
                {t('pages.auth.register.referral')}
              </a>
            </Typography>
          </Box>
        )}

      {promoActive && (
          <Box
            sx={{
              p: 2,
              mb: 3,
              borderRadius: 1,
              // highlight the background color in transparent yellow
              bgcolor: (theme) => (theme.palette.mode === 'light' ? 'warning.light' : 'warning.dark'),
              
              color: 'text.primary',
            }}
          >
            <Typography variant="body2">
              <a href={getButtonPath()} style={{ textDecoration: 'none', color: 'inherit' }}>
                {t('pages.auth.register.promo_buytext')}
              </a>
            </Typography>
          </Box>
        )}



      <FormProvider methods={methods}>
        <Typography variant="h5" sx={{ mb: 3 }}>
          {t('pages.account.personalInfo')}
        </Typography>
        
        <Box
          rowGap={2.5}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{ xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
          sx={{ mb: 3 }}
        >

          <RHFTextField name="name" label={t('pages.account.displayName')}  />
          <RHFTextField name="emailAddress" label={t('pages.account.email')} disabled='true' />
          <RHFTextField name="phoneNumber" label={t('pages.account.phoneNumber')} disabled='true' />
        </Box>
  
        <LoadingButton
          color="inherit"
          size="large"
          onClick={() => onUpdateProfile(methods.getValues())}
          variant="contained"
        >
          {t('pages.account.updateProfile')}
        </LoadingButton>
  
        <Typography variant="h5" sx={{ my: 5 }}>
          {t('pages.account.changePassword')}
        </Typography>
  
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2.5}>
            <RHFTextField
              name="oldPassword"
              label={t('pages.account.oldPassword')}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify icon={showPassword ? 'carbon:view' : 'carbon:view-off'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
  
            <RHFTextField
              name="newPassword"
              label={t('pages.account.newPassword')}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify icon={showPassword ? 'carbon:view' : 'carbon:view-off'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
  
            <RHFTextField
              name="confirmNewPassword"
              label={t('pages.account.confirmNewPassword')}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify icon={showPassword ? 'carbon:view' : 'carbon:view-off'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
  
            <LoadingButton
              color="inherit"
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {t('pages.account.changePassword')}
            </LoadingButton>
  
          </Stack>
        </form>
      </FormProvider>
    </AccountLayout>
  );
  
}
