import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink, useMatch, useResolvedPath } from 'react-router-dom';
import { Link } from '@mui/material';
import Iconify from 'src/components/iconify';
import { StyledNavItem } from './styles';
import { m } from 'framer-motion';

// ----------------------------------------------------------------------

export const NavItem = forwardRef(
  ({ item, open, subItem, isExternalLink, ...other }, ref) => {
    const { title, path, children } = item;
    const isRootPath = path === '/';


    // Resolve the full path for matching against the current location
    const resolved = useResolvedPath(path);
    // Check if the current path is active (allowing subpaths to match)
    const match = useMatch({ path: resolved.pathname, end: isRootPath ? true : false });

    const renderContent = (
      console.log('Render Content active match:', match),
      <StyledNavItem
        ref={ref}
        disableRipple
        subItem={subItem}
        active={match}
        open={open}
        // {...other}
      >
        {title}

        {!!children && <Iconify width={16} icon="carbon:chevron-down" sx={{ ml: 1 }} />}
      </StyledNavItem>
    );
    

    // ExternalLink
    if (isExternalLink) {
      return (
        <Link href={path} target="_blank" rel="noopener" color="inherit" underline="none">
          {renderContent}
        </Link>
      );
    }

    // Has child
    if (children) {
      return renderContent;
    }

    // Default
    return (
      <Link component={RouterLink} to={path} color="inherit" underline="none">
        {renderContent}
      </Link>
    );
  }
);

NavItem.propTypes = {
  isExternalLink: PropTypes.bool,
  item: PropTypes.object,
  open: PropTypes.bool,
  subItem: PropTypes.bool,
};

NavItem.displayName = 'NavItem';
