import { Navigate, useRoutes } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { getAnalytics, logEvent } from "firebase/analytics";
// layouts
import getRoutes from './config.js';

import { useAuth } from 'src/AuthContext';

// ----------------------------------------------------------------------

export default function Router({ t, i18n }) {
  const { currentUser,logout, loading } = useAuth();
  const location = useLocation(); // Hook to get location object
  const analytics = getAnalytics(); // Initialize Firebase Analytics

  // Effect to log page views
  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_path: location.pathname,
      // You can add more details here if necessary
    });
  }, [location]); // Re-run this effect when location changes

  const routes = getRoutes(currentUser, logout, loading, t, i18n);
 
  
  return useRoutes(routes);
}
