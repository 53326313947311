import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import {
  Link,
  Stack,
  Drawer,
  Avatar,
  Divider,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from '@mui/material';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import useActiveLink from 'src/hooks/useActiveLink';
// config
import { NAV } from 'src/config-global';
// routes
import { paths } from 'src/routes/paths';
// _mock
import _mock from 'src/_mock';
// components
import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';
import { useAuth } from 'src/AuthContext';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
export const useNavigations = () => {
  const { t } = useTranslation();
  const { currentUser, subscriptionSource } = useAuth();
  const getButtonPath = () => {
    if (subscriptionSource === 'app_store') {
      return paths.appleBilling;
    } else if (subscriptionSource === 'play_store') {
      return paths.googleBilling;
    } else {
      return paths.stripeBilling + '?prefilled_email=' + (currentUser ? currentUser.email : '');
    }
  };
    
  const navigations = [
    {
      title: t('pages.account.menu.profile'),
      path: paths.accountPage,
      icon: <Iconify icon="carbon:user" />,
    },
    {
      title: t('pages.account.menu.buySubscription'),
      path: paths.accountSubPage,
      icon: <Iconify icon="carbon:shopping-cart" />,
    },
    {
      title: t('pages.account.menu.manageSubscription'),
      path: getButtonPath(),
      icon: <Iconify icon="carbon:badge" />,
      newWindow: true,
    },

    // Uncomment or add more navigation items as needed
    // {
    //   title: '订单',
    //   path: paths.accountOrderPage,
    //   icon: <Iconify icon="carbon:document" />,
    // },
  ];

  return navigations;
};

// ----------------------------------------------------------------------

export default function AccountMenu({ open, onClose }) {
  const isMdUp = useResponsive('up', 'md');
  const { currentUser, logout, memberTier } = useAuth();

    
    
  const navigations = useNavigations();
  const renderContent = (
    <Stack
      sx={{
        flexShrink: 0,
        borderRadius: 2,
        width: 1,
        ...(isMdUp && {
          width: NAV.W_DRAWER,
          border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`,
        }),
      }}
    >
      <Stack spacing={2} sx={{ p: 3, pb: 2 }}>
        <Stack spacing={2} direction="row" alignItems="center">
          <Avatar src={_mock.image.avatar(1)} sx={{ width: 64, height: 64 }} />
          <Stack
            direction="row"
            alignItems="center"
            sx={{ typography: 'caption', cursor: 'pointer', '&:hover': { opacity: 0.72 } }}
          >
          </Stack>
        </Stack>

        <Stack spacing={0.5}>
          <TextMaxLine variant="subtitle1" line={1}>
            {currentUser ? memberTier : t('pages.auth.unregistered')}
          </TextMaxLine>
        <TextMaxLine variant="body2" line={1} sx={{ color: 'text.secondary' }}>
          {currentUser && currentUser.email ? currentUser.email : t('pages.auth.unregistered')}
        </TextMaxLine>
        </Stack>
      </Stack>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Stack sx={{ my: 1, px: 2 }}>
        {navigations.map((item) => (
          <MenuItem key={item.title} item={item} />
        ))}
      </Stack>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Stack sx={{ my: 1, px: 2 }}>
        <ListItemButton component={RouterLink} to={'/'} onClick={logout}
          sx={{
            px: 1,
            height: 44,
            borderRadius: 1,
          }}
        >
          <ListItemIcon>
            <Iconify icon="carbon:logout" />
          </ListItemIcon>
          <ListItemText
            primary={t('pages.auth.logout')}
            primaryTypographyProps={{
              typography: 'body2',
            }}
          />
        </ListItemButton>
      </Stack>
    </Stack>
  );

  return (
    <>
      {isMdUp ? (
        renderContent
      ) : (
        <Drawer
          open={open}
          onClose={onClose}
          ModalProps={{ keepMounted: true }}
          PaperProps={{
            sx: {
              width: NAV.W_DRAWER,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </>
  );
}

AccountMenu.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

// ----------------------------------------------------------------------

function MenuItem({ item }) {
  const { active } = useActiveLink(item.path);
  const openNewWindow = (url) => {
    window.open(url, '_blank');
  };
  return (
    <Link
      component={RouterLink}
      key={item.title}
      to={item.newWindow ? undefined : item.path}
      onClick={item.newWindow ? () => openNewWindow(item.path) : undefined}
      color={active ? 'primary' : 'inherit'}
      underline="none"
    >
      <ListItemButton
        sx={{
          px: 1,
          height: 44,
          borderRadius: 1,
        }}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText
          primary={item.title}
          primaryTypographyProps={{
            typography: 'body2',
            ...(active && {
              typography: 'subtitle2',
            }),
          }}
        />
      </ListItemButton>
    </Link>
  );
}

MenuItem.propTypes = {
  item: PropTypes.shape({
    icon: PropTypes.node,
    path: PropTypes.string,
    title: PropTypes.string,
  }),
};
