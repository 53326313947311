import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Stack, Container, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';
// _mock
import { _pricing01 } from 'src/_mock';
//
import { MotionViewport, varFade } from 'src/components/animate';
import { PlanCard } from '../pricing-01';

// ----------------------------------------------------------------------

export default function PricingHome({ t }) {
  const [subscription, setSubscription] = useState('monthly');
  const pricingList = _pricing01(t);
  const handleChangeSubscription = (event, newValue) => {
    if (newValue !== null) {
      setSubscription(newValue);
    }
  };

  return (
    <Container
      component={MotionViewport}
      sx={{
        pt: { xs: 6, md: 5 },
        pb: { xs: 5, md: 10 },
      }}
    >
      <Typography variant="h3" align="center" paragraph>
        {t('pages.pricing.title')}
      </Typography>

      <Typography align="center" sx={{ color: 'text.secondary' }}>
        {t('pages.pricing.description')}
      </Typography>

      <Stack sx={{ my: { xs: 5, md: 8 } }}>
        <ToggleButtonGroup
          exclusive
          color="standard"
          value={subscription}
          onChange={handleChangeSubscription}
          sx={{
            mx: 'auto',
            border: 0,
            bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
            '& .MuiToggleButton-root': {
              m: 0,
              typography: 'overline',
              color: 'text.secondary',
              '&:hover': {
                bgcolor: 'transparent',
              },
              '&.Mui-selected': {
                bgcolor: 'text.primary',
                color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                '&:hover': {
                  bgcolor: 'text.primary',
                },
              },
            },
          }}
        >
          <ToggleButton value="monthly">{t('pages.pricing.monthly')}</ToggleButton>
          <ToggleButton value="yearly">{t('pages.pricing.yearly')}</ToggleButton>
        </ToggleButtonGroup>
      </Stack>

      <Box
      sx={{
        gap: 4,
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          md: 'repeat(3, 1fr)',
        },
      }}
    >
      {pricingList.map((plan) => (
        <PlanCard key={plan.license} plan={plan} isYearly={subscription === 'yearly'} t={t} />
      ))}
</Box>

    </Container>
  );
}
